<template>
  <v-list light class="pa-0">
    <template v-for="(systemLevel, index) in advisorySystemLevels">
      <v-list-item
        :key="systemLevel.level"
        :class="setLevelClass(systemLevel.level)"
        light
        class="py-3"
      >
        <v-list-item-icon>
          <v-icon large>
            mdi-shield-alert-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="mb-5 text-h6  pgi-system__title">
            {{ systemLevel.level }}
            <!-- <span class="font-weight-medium" color="black">{{
              systemLevel.systemDesc
            }}</span> -->
            <v-icon>mdi-arrow-right</v-icon>
            <v-list-item-subtitle
              v-text="systemLevel.systemDesc"
              class="ml-3 d-inline text-subtitle-1"
            ></v-list-item-subtitle>
          </v-list-item-title>

          <v-list-item-title class="mb-1 text-subtitle-2">
            Reason
          </v-list-item-title>
          <v-list-item-subtitle class="mb-3 text-wrap">
            {{ systemLevel.reason }}
          </v-list-item-subtitle>

          <v-list-item-title class="mb-1 text-subtitle-2">
            Response
          </v-list-item-title>
          <v-list-item-subtitle class="mb-3 text-wrap">
            {{ systemLevel.response }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider
        v-if="index < advisorySystemLevels.length - 1"
        :key="index"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("advisorySystem", ["advisorySystemLevels"])
  },
  methods: {
    setLevelClass(level) {
      const _level = level.toLowerCase();
      return `pgi-system pgi-system--${_level}`;
    }
  }
};
</script>

<style lang="scss" scoped>
$systemGreenColor: #4caf50;
$systemBlueColor: #2196f3;
$systemYellowColor: #f8d640;
$systemOrangeColor: #ff9800;
$systemRedColor: #f44336;

$systemBgAlpha: 0.09;

.pgi-system {
  border-left: 8px solid;

  &--green {
    background-color: rgba($systemGreenColor, $systemBgAlpha);
    border-left-color: $systemGreenColor;

    .v-icon,
    .pgi-system__title {
      color: $systemGreenColor;
    }
  }

  &--blue {
    background-color: rgba($systemBlueColor, $systemBgAlpha);
    border-left-color: $systemBlueColor;

    .v-icon,
    .pgi-system__title {
      color: $systemBlueColor;
    }
  }

  &--yellow {
    background-color: rgba($systemYellowColor, $systemBgAlpha);
    border-left-color: $systemYellowColor;

    .v-icon {
      color: $systemYellowColor;
    }

    .pgi-system__title {
      color: mix(black, $systemYellowColor, 5%);
    }
  }

  &--orange {
    background-color: rgba($systemOrangeColor, $systemBgAlpha);
    border-left-color: $systemOrangeColor;

    .v-icon {
      color: $systemOrangeColor;
    }

    .v-icon,
    .pgi-system__title {
      color: $systemOrangeColor;
    }
  }

  &--red {
    background-color: rgba($systemRedColor, $systemBgAlpha);
    border-left-color: $systemRedColor;

    .v-icon,
    .pgi-system__title {
      color: $systemRedColor;
    }
  }

  .v-list-item__icon {
    margin-top: 0.5rem;
  }
}
</style>
