<template>
  <div>
    <div v-if="currentUser">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-row v-on="on" v-bind="attrs" align="center" no-gutters>
            <v-col cols="2">
              <v-avatar size="36px">
                <img
                  :alt="`${currentUser.user_metadata.name}`"
                  :src="currentUser.user_metadata.avatar"
                />
              </v-avatar>
            </v-col>

            <v-col class="pl-3 hidden-xs-only" cols="9">
              <span class="caption">{{ currentUser.email }}</span>
            </v-col>
            <v-col class="pl-1 hidden-xs-only" cols="1">
              <v-icon>mdi-chevron-down</v-icon>
            </v-col>
          </v-row>
        </template>
        <v-list color="background">
          <v-list-item @click="showAccountDialog = true">
            <v-list-item-title>
              <div class="d-flex">
                <v-icon small class="mr-4">mdi-account</v-icon>Account
              </div>
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-title>
              <div class="d-flex">
                <v-icon small class="mr-4"
                  >mdi-card-account-details-outline</v-icon
                >
                Profile
              </div>
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item @click="handleLogout">
            <v-list-item-title>
              <div class="d-flex">
                <v-icon small class="mr-4">mdi-power-standby</v-icon>
                Sign Out
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-dialog
      overlay-opacity="0.8"
      overlay-color="#2C4779"
      v-model="showAccountDialog"
      transition="scroll-y-transition"
      max-width="600"
      :scrollable="true"
      content-class="pgi-account-dialog"
    >
      <v-card light>
        <v-card-title class="pa-0">
          <v-toolbar color="dashCard" dark>
            <v-btn icon>
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-toolbar-title>Account</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showAccountDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar></v-card-title
        >

        <v-card-text class="">
          <UserAccountForm :currentUser="currentUser" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserAccountForm from "@/components/UserAccountForm";

export default {
  name: "UserMenu",
  components: {
    UserAccountForm
  },
  data() {
    return {
      showAccountDialog: false
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"])
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("auth", ["attemptLogout"]),
    handleLogout() {
      this.attemptLogout()
        .then(resp => {
          this.$router.push("login");
          this.initAuth;
          console.log("logged out", resp);
        })
        .catch(error => {
          alert("problem with logout");
          //location.reload();

          console.error("problem with logout", error);
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
