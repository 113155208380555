<template>
  <v-app :style="{ background: appBackground }">
    <MainAppBar @toggleCollectionMenu="toggleCollectionMenu" />
    <v-main class="px-4">
      <slot />
    </v-main>

    <BaseFooter />

    <v-navigation-drawer
      v-model="collectionsDrawer"
      color="dashCard"
      width="400"
      overlay-color="background darken-5"
      app
      right
      bottom
      temporary
    >
      <v-app-bar color="transparent" flat>
        <v-toolbar-title>
          <v-icon left>mdi-playlist-plus</v-icon>
          Collections
          <v-chip class="ma-2" color="grey" text-color="grey darken-4">
            <v-avatar left class="grey darken-4" color="white--text">
              3
            </v-avatar>
            Active
          </v-chip>
        </v-toolbar-title>
      </v-app-bar>

      <v-list two-line subheader>
        <v-subheader>
          Simulations
        </v-subheader>

        <v-list-item link>
          <v-list-item-avatar>
            <v-icon class="green accent-4 white--text">
              mdi-creation
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>All Latest Simulations</v-list-item-title>

            <v-list-item-subtitle
              >Keeping track of simulations</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon>
              <v-icon color="grey lighten-1">
                mdi-share
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          Trends & Projections
        </v-subheader>

        <v-list-item link>
          <v-list-item-avatar>
            <v-icon class="blue accent-3 white--text">
              mdi-chart-multiple
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>2019 Trend Collection 1</v-list-item-title>

            <v-list-item-subtitle
              >Shared with Alex and Alicia</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon ripple>
              <v-icon color="grey lighten-1">
                mdi-share
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item link>
          <v-list-item-avatar>
            <v-icon class="blue accent-3 white--text">
              mdi-chart-multiple
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>2021 Trend Collection 3</v-list-item-title>

            <v-list-item-subtitle
              >Shared with Alicia, Miguel, Level 3 Agents</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon ripple>
              <v-icon color="grey lighten-1">
                mdi-share
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-subheader>
          Archived
        </v-subheader>

        <v-list-item link>
          <v-list-item-avatar>
            <v-icon color="white lighten-2">
              mdi-creation
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >2023-2025 Projections for XYZ Index</v-list-item-title
            >

            <v-list-item-subtitle
              >Share with Level 2 team for internal audit</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon ripple>
              <v-icon color="grey lighten-1">
                mdi-share
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>
<script>
import BaseFooter from "@/components/BaseFooter";
import MainAppBar from "@/components/MainAppBar";

export default {
  components: { BaseFooter, MainAppBar },
  data() {
    return {
      collectionsDrawer: false
    };
  },
  mounted() {},
  computed: {
    appBackground() {
      return `radial-gradient(farthest-side ellipse at 10% 0, #2e4e86, #253667)`;
    }
  },
  methods: {
    toggleCollectionMenu() {
      this.collectionsDrawer = !this.collectionsDrawer;
    }
  }
};
</script>

<style lang="scss" scoped></style>
