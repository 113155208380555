import { render, staticRenderFns } from "./BaseFooter.vue?vue&type=template&id=03a7e6d7&scoped=true&"
import script from "./BaseFooter.vue?vue&type=script&lang=js&"
export * from "./BaseFooter.vue?vue&type=script&lang=js&"
import style0 from "./BaseFooter.vue?vue&type=style&index=0&id=03a7e6d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a7e6d7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VFooter})
