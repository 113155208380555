var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pgi-user-account-form"},[(_vm.isLoading)?_c('div',{staticClass:"pa-16 d-flex align-center justify-center"},[(_vm.isLoading)?_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e()],1):_vm._e(),(_vm.showAlert && !_vm.isLoading)?_c('v-alert',{staticClass:"mt-5",attrs:{"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]):_vm._e(),(!_vm.isLoading)?[(_vm.checkPendingEmailChange)?_c('p',{staticClass:"mt-6 mb-4"},[_c('v-alert',{attrs:{"dense":"","text":"","type":"warning"}},[_vm._v("Pending email change to: "),_c('strong',[_vm._v(_vm._s(_vm.checkPendingEmailChange))]),_vm._v(". Check your email to confirm.")])],1):_vm._e(),_c('div',{staticClass:"mt-6 mb-4 text-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',[_c('v-avatar',{staticClass:"pgi-user-account-form__avatar",attrs:{"size":"72px"}},[_c('img',{attrs:{"src":_vm.credentials.avatar}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-icon',{on:{"click":_vm.handleEditAvatar}},[_vm._v("mdi-image-edit-outline")])],1):_vm._e()],1)],1)],1)]}}],null,false,767820269)})],1),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",staticClass:"flex-grow-1",on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin($event)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","label":"Name","outlined":""},model:{value:(_vm.credentials.name),callback:function ($$v) {_vm.$set(_vm.credentials, "name", $$v)},expression:"credentials.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pgi-user-email-field",attrs:{"error-messages":errors,"type":"email","label":"Email Address","outlined":""},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Name","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"text","label":"Carrier","outlined":""},model:{value:(_vm.credentials.carrier),callback:function ($$v) {_vm.$set(_vm.credentials, "carrier", $$v)},expression:"credentials.carrier"}})]}}],null,true)}),_c('v-divider',{staticClass:"mb-5"}),(_vm.changePassword)?[_c('validation-provider',{attrs:{"name":"Password","rules":{
              confirmed: 'confirmPassword',
              regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","label":"Password","outlined":""},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","label":"Confirm Password","outlined":"","hint":"Passwords must be at least 8 characters long and include a number, letter, and special character (@ $ ! % * # ? &).","persistent-hint":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})]:_vm._e(),_c('p',{staticClass:"mb-8 text-caption d-flex justify-space-between"},[_c('a',{staticClass:"background--text",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleEditPassword($event)}}},[_vm._v(_vm._s(_vm.changePassword ? "Cancel Password Change" : "Change Password"))])]),_c('v-divider'),_c('div',{staticClass:"mt-5 d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary darken-2","disabled":invalid},on:{"click":_vm.handleAccountUpdate}},[_vm._v("Update")])],1)],2)]}}],null,false,1941247163)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }