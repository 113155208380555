<template>
  <div class="pgi-user-account-form">
    <div v-if="isLoading" class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
        v-if="isLoading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-alert
      v-if="showAlert && !isLoading"
      dense
      outlined
      type="success"
      class="mt-5"
    >
      {{ alertMessage }}
    </v-alert>

    <template v-if="!isLoading">
      <p v-if="checkPendingEmailChange" class="mt-6 mb-4">
        <v-alert dense text type="warning"
          >Pending email change to:
          <strong>{{ checkPendingEmailChange }}</strong
          >. Check your email to confirm.</v-alert
        >
      </p>
      <div class="mt-6 mb-4 text-center">
        <v-hover>
          <template v-slot:default="{ hover }">
            <span>
              <v-avatar size="72px" class="pgi-user-account-form__avatar">
                <img :src="credentials.avatar" />
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#036358">
                    <v-icon @click="handleEditAvatar"
                      >mdi-image-edit-outline</v-icon
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-avatar>
            </span>
          </template>
        </v-hover>
      </div>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form ref="form" class="flex-grow-1" @submit.prevent="handleLogin">
          <validation-provider v-slot="{ errors }" name="Name" rules="max:50">
            <v-text-field
              v-model="credentials.name"
              :error-messages="errors"
              type="text"
              label="Name"
              outlined
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            rules="required|email"
          >
            <v-text-field
              v-model="credentials.email"
              :error-messages="errors"
              type="email"
              label="Email Address"
              outlined
              class="pgi-user-email-field"
            ></v-text-field>
          </validation-provider>
          <validation-provider v-slot="{ errors }" name="Name" rules="max:50">
            <v-text-field
              v-model="credentials.carrier"
              :error-messages="errors"
              type="text"
              label="Carrier"
              outlined
            ></v-text-field>
          </validation-provider>
          <v-divider class="mb-5"></v-divider>

          <template v-if="changePassword">
            <validation-provider
              v-slot="{ errors }"
              name="Password"
              :rules="{
                confirmed: 'confirmPassword',
                regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
              }"
            >
              <v-text-field
                v-model="credentials.password"
                :error-messages="errors"
                type="password"
                label="Password"
                outlined
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Confirm Password"
              vid="confirmPassword"
            >
              <v-text-field
                v-model="confirmPassword"
                :error-messages="errors"
                type="password"
                label="Confirm Password"
                outlined
                hint="Passwords must be at least 8 characters long and include a number, letter, and special character (@ $ ! % * # ? &)."
                persistent-hint
              ></v-text-field>
            </validation-provider>
          </template>
          <p class="mb-8 text-caption d-flex justify-space-between">
            <a
              href="#"
              @click.prevent="handleEditPassword"
              class="background--text"
              >{{
                changePassword ? "Cancel Password Change" : "Change Password"
              }}</a
            >
          </p>

          <v-divider></v-divider>

          <div class="mt-5 d-flex justify-end">
            <v-btn
              class="mr-4"
              color="primary darken-2"
              @click="handleAccountUpdate"
              :disabled="invalid"
              >Update</v-btn
            >
          </div>
        </v-form>
      </validation-observer>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { tempUserAvatar } from "@/helpers/tempUserAvatar";

export default {
  name: "UserAccountForm",
  props: {
    currentUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAlert: false,
      alertMessage: "Account changes have been saved!",
      isLoading: false,
      changePassword: false,
      confirmPassword: "",
      initialEmail: "",
      credentials: {
        name: this.currentUser.user_metadata.name
          ? this.currentUser.user_metadata.name
          : "",
        email: this.currentUser.email,
        password: "",
        avatar: this.currentUser.user_metadata.avatar
          ? this.currentUser.user_metadata.avatar
          : tempUserAvatar(this.currentUser.email),
        carrier: this.currentUser.user_metadata.carrier
          ? this.currentUser.user_metadata.carrier
          : ""
      }
    };
  },
  computed: {
    checkPendingEmailChange() {
      return (
        this.currentUser?.email !== this.currentUser?.new_email &&
        this.currentUser?.new_email
      );
    }
  },
  mounted() {
    // Capture initial email in case user wants to change it and need to compare.
    this.initialEmail = this.currentUser.email;
    console.log("pending update? ", this.currentUser.user_metadata.new_email);
  },
  methods: {
    ...mapActions("auth", ["updateUserAccount"]),
    async handleAccountUpdate() {
      this.isLoading = true;
      this.changePassword = false;
      const userData = {
        email: this.credentials.email,
        password: this.credentials.password,
        // data prop updates netlify identity user_metadata
        data: {
          name: this.credentials.name,
          avatar: this.credentials.avatar,
          carrier: this.credentials.carrier,
          emailBeforeChange: this.initialEmail
        }
      };

      try {
        const updatedUserInfo = await this.updateUserAccount({ ...userData });

        // Need to only change MC if the user confirms their email address.
        // Need to find mechansim to run fnc only if user confirms
        // Check if user is attempting to change email address.
        if (
          updatedUserInfo &&
          userData.email &&
          userData.email !== this.initialEmail
        ) {
          console.log("emails are different");

          this.alertMessage =
            "Check your email to confirm and complete the email address change. All other changes has been saved.";
        }

        console.log("Account has been updated.");
        // Set the user's email back to the initial email in case they changed it
        // bc they still need to confirm the email to make the change stick
        // So we show the initial email until that happens.
        this.credentials.email = this.initialEmail;

        // Clear the password fields
        this.credentials.password = "";
        this.confirmPassword = "";

        setTimeout(() => {
          this.isLoading = false;
          this.showAlert = true;
        }, 1500);
      } catch (error) {
        console.error("Failed to update user: ", error);
      }
    },
    handleEditAvatar() {
      console.log("edit avatar");
    },
    handleEditPassword() {
      if (this.changePassword) {
        this.credentials.password = "";
        this.confirmPassword = "";

        this.changePassword = false;
      } else {
        this.changePassword = true;
      }
    }
  }
};
</script>

<style lang="scss">
.pgi-user-account-form {
  .pgi-confirm-field {
    .v-text-field__details {
      display: none;
    }
  }

  &__avatar {
    position: relative;
  }

  .pgi-user-email-field {
    .v-text-field__details {
      margin-bottom: 1rem;
    }
  }
}
</style>
