<template>
  <!-- <div> -->
  <v-app-bar ref="mainAppBar" app elevate-on-scroll class="px-4 pgi-app-bar">
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          alt="PlanGap Insights Logo"
          class="shrink mr-2"
          contain
          src="@/assets/images/pg-insights-logo-r.svg"
          transition="scale-transition"
          width="220"
        />
      </router-link>
    </div>

    <div class="ml-4">
      <AdvisorySystem />
    </div>

    <v-spacer></v-spacer>

    <!-- <v-alert
      dense
      text
      border="left"
      color="accent"
      class="mb-0 body-text-2 d-none d-md-block"
    >
      <template v-slot:prepend>
        <v-icon color="accent" class="mr-2">mdi-alert-circle-outline</v-icon>
      </template>
      2021 Index calculations are now available
    </v-alert> -->

    <v-spacer></v-spacer>

    <UserMenu />

    <!-- <v-divider vertical class="mx-5"></v-divider> -->

    <!-- <v-btn icon @click="showSearchDialog = true">
      <v-icon>mdi-magnify</v-icon>
    </v-btn> -->

    <v-dialog
      overlay-opacity="0.95"
      overlay-color="#1A284B"
      v-model="showSearchDialog"
      transition="scroll-y-transition"
      max-width="800"
      :scrollable="true"
      content-class="pgi-search-dialog"
    >
      <!-- <h2 class="text-h4">Search</h2> -->
      <v-text-field
        clearable
        label="Type to Search"
        append-icon="mdi-magnify"
        class="pgi-search-field"
      ></v-text-field>
    </v-dialog>

    <!-- <v-btn icon>
      <v-badge color="red" dot overlap>
        <v-icon>mdi-bell-outline</v-icon></v-badge
      >
    </v-btn> -->

    <v-menu
      v-model="showNotificationPopover"
      :close-on-click="true"
      :close-on-content-click="false"
      max-width="300"
      min-width="300"
      left
      offset-y
      transition="slide-y-transition"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-badge color="grey" dot overlap>
            <v-icon>mdi-bell-outline</v-icon></v-badge
          >
        </v-btn>
      </template> -->

      <v-card :elevation="24" light>
        <v-list color="white" light>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center"
                ><v-icon left large color="grey lighten-2"
                  >mdi-bell-outline</v-icon
                >
                <div class="mt-4  grey--text text-lighten-2">
                  No Unread Notifications
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="background" text @click="handleClosePopover">
            Close
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-menu>

    <!-- <v-btn class="mx-2" fab small color="primary" @click="toggleCollectionMenu">
      <v-icon>
        mdi-playlist-plus
      </v-icon>
    </v-btn> -->
  </v-app-bar>

  <!-- </div> -->
</template>

<script>
import AdvisorySystem from "@/components/AdvisorySystem";
import UserMenu from "@/components/UserMenu";

export default {
  components: { AdvisorySystem, UserMenu },
  data() {
    return {
      drawer: false,
      // appBarColorClass: "",
      showNotificationPopover: false,
      showSearchDialog: false
    };
  },

  computed: {
    //
  },
  methods: {
    toggleCollectionMenu() {
      this.$emit("toggleCollectionMenu");
    },
    parseJSON(res) {
      return res.json();
    }
    // onScroll() {
    //   this.appBarColorClass = "is-scrolled";
    // },
  }
};
</script>

<style lang="scss" scoped>
.pgi-app-bar {
  background: transparent !important;
  &.v-app-bar--is-scrolled {
    background: rgba(#1b284e, 0.97) !important;
  }
}
</style>

<style lang="scss">
.pgi-search-dialog.v-dialog {
  box-shadow: none;
}

.pgi-search-field {
  input,
  label.v-label {
    font-size: 1.5rem;
  }

  label.v-label {
    height: 30px;
  }
}
</style>
