<template>
  <div>
    <v-menu
      v-model="showAdvisoryPopover"
      :close-on-click="true"
      :close-on-content-click="false"
      max-width="420"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :color="checkActiveAdvisoryLevel.color"
          elevation="2"
          fab
          outlined
          small
        >
          <v-icon>mdi-shield-alert-outline</v-icon>
        </v-btn>
      </template>

      <v-card :elevation="24" light>
        <v-list :color="checkActiveAdvisoryLevel.color" dark>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><v-icon left>mdi-shield-alert-outline</v-icon> Advisory Status:
                <strong>{{
                  checkActiveAdvisoryLevel.level
                }}</strong></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap text-subtitle-1">{{
                checkActiveAdvisoryLevel.systemDesc
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><strong>Reason</strong></v-list-item-title>
              <v-list-item-subtitle class="text-wrap">{{
                checkActiveAdvisoryLevel.reason
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><strong>Response</strong></v-list-item-title>
              <v-list-item-subtitle class="text-wrap">{{
                checkActiveAdvisoryLevel.response
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="text-wrap">
                <a
                  @click.stop.prevent="
                    advisoryLevelsDialog = true;
                    showAdvisoryPopover = false;
                  "
                  href="#"
                  class="dashCard--text"
                  >Understand PlanGap Social Security Advisory System Levels</a
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="background" text @click="handleClosePopover">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-dialog
      overlay-opacity="0.8"
      overlay-color="#2C4779"
      v-model="advisoryLevelsDialog"
      transition="scroll-y-transition"
      max-width="800"
      :scrollable="true"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="dashCard">
            <v-toolbar-title>PlanGap Advisory System Levels</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="advisoryLevelsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar></v-card-title
        >

        <v-card-text style="height: 600px;" class="pa-0">
          <AdvisorySystemLevelDescriptions />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AdvisorySystemLevelDescriptions from "@/components/AdvisorySystemLevelDescriptions";

export default {
  components: {
    AdvisorySystemLevelDescriptions
  },
  data() {
    return {
      showAdvisoryPopover: true,
      tempStatusDir: "up",
      advisoryLevelsDialog: false
    };
  },
  mounted() {
    if (this.hasSeenAdvisory) {
      this.showAdvisoryPopover = false;
    }
  },
  computed: {
    ...mapGetters("advisorySystem", [
      "hasSeenAdvisory",
      "advisorySystemLevels"
    ]),
    checkActiveAdvisoryLevel() {
      // const activeAdvisory = this.advisorySystemLevels.find(
      //   advisorySystem => advisorySystem.active === true
      // );
      return this.advisorySystemLevels[0];
    }
  },
  methods: {
    ...mapActions("advisorySystem", [
      "setHasSeenAdvisory",
      "setActiveAdvisoryLevel"
    ]),
    handleClosePopover() {
      this.showAdvisoryPopover = false;
      this.setHasSeenAdvisory(true);
      //   const currentAdvisoryIndex = this.advisorySystemLevels.findIndex(alert => {
      //     return alert.active === true;
      //   });

      //   if (currentAdvisoryIndex === 0) {
      //     this.tempStatusDir = "up";
      //   }
      //   if (currentAdvisoryIndex === 4) {
      //     this.tempStatusDir = "down";
      //   }

      //   let nextAdvisory;

      //   if (this.tempStatusDir === "up") {
      //     nextAdvisory = currentAdvisoryIndex + 1;
      //   }
      //   if (this.tempStatusDir === "down") {
      //     nextAdvisory = currentAdvisoryIndex - 1;
      //   }

      //   setTimeout(() => {
      //     this.setActiveAdvisoryLevel(nextAdvisory);
      //   }, 1500);
    }
  }
};
</script>

<style></style>
