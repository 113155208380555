<template>
  <div>
    <v-footer app absolute color="transparent" class="text-body-2 pgi-footer">
      <div>Copyright {{ currentYear }} PlanGap</div>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
.pgi-footer {
  padding-top: 0.5rem;
  border-top: 1px solid rgba(white, 0.25) !important;
}
</style>
